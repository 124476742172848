<script lang="ts">
import ApocSvg from '@/components/common/ApocSvg.vue';
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IApocSelectOption } from '@/types';
import { useI18n } from 'vue-i18n';

export type ApocSelectPropType = string | number | boolean | Date | undefined;

export default defineComponent({
	name: 'ApocSelectV2',
	components: { ApocSvg },
	props: {
		selectOptionParent: {
			type: Object as PropType<HTMLElement | undefined>,
			required: false,
		},
		optionList: {
			type: Array as PropType<IApocSelectOption[]>,
			required: true,
		},
		selectedValue: {
			type: Object as PropType<IApocSelectOption>,
			required: false,
		},
		optionClassList: {
			type: Array as PropType<string[]>,
			required: false,
		},
		onChange: {
			type: Function as PropType<(data: string) => void>,
			required: false,
		},
		placeholder: {
			type: String as PropType<string>,
			required: false,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		exceptPlaceholderFromList: {
			type: Boolean as PropType<boolean>,
			required: false,
		},
		showArrow: {
			type: Boolean as PropType<boolean>,
			require: false,
			default: true,
		},
		showCheck: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: true,
		},
		showCancel: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: true,
		},
		showHeader: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: true,
		},
		rightCheck: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		align: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		arrowImgSrc: {
			type: String as PropType<string>,
			default: 'assets/images/common/icons/icon-arrow-down.svg',
			required: false,
		},
		cover: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		setMaxHeightToBottom: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		hasPagination: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		isLoading: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
	},
	emits: ['update-list-func'],
	setup(props, { emit }) {
		const { t } = useI18n();
		const route = useRoute();
		const listOpened = ref<boolean>(false);
		const compOptionList = computed(() => props.optionList || []);
		const compShowArrow = computed(() => props.showArrow);
		const compSelectedValue = computed(() => props.selectedValue);
		let parentElement = props.selectOptionParent || window.document.body;
		const selectedValueElement = ref<HTMLElement | null>(null);
		const comIsLoading = computed(() => props.isLoading);
		const optionListElement = ref<HTMLElement | null>(null);

		const handleSelect = (v?: IApocSelectOption) => {
			if (props.disabled) return;
			if (props.onChange) props.onChange(v ? v.value : '');
			listOpened.value = false;
		};

		const clickSelectBox = () => {
			if (props.disabled) return;
			listOpened.value = !listOpened.value;
		};

		const closeOptionList = () => {
			listOpened.value = false;
		};

		const setOptionListStyle = () => {
			if (selectedValueElement.value && optionListElement.value) {
				// width는 계속 업데이트
				optionListElement.value.style.width = selectedValueElement.value.getBoundingClientRect().width + 'px';
				if (
					parentElement.getBoundingClientRect().bottom - selectedValueElement.value.getBoundingClientRect().top <= 0 ||
					selectedValueElement.value.getBoundingClientRect().bottom - parentElement.getBoundingClientRect().top <= 0
				) {
					return;
				}

				if (
					parentElement.getBoundingClientRect().bottom -
						(selectedValueElement.value.getBoundingClientRect().bottom + optionListElement.value.getBoundingClientRect().height) <=
					0
				) {
					optionListElement.value.style.top =
						selectedValueElement.value.getBoundingClientRect().top - optionListElement.value.getBoundingClientRect().height + 'px';
				} else {
					optionListElement.value.style.top = selectedValueElement.value.getBoundingClientRect().bottom + 'px';
				}
				if (props.setMaxHeightToBottom) {
					optionListElement.value.style.maxHeight =
						parentElement.getBoundingClientRect().height - optionListElement.value.getBoundingClientRect().top - 24 + 'px';
				}

				if (!props.align) {
					optionListElement.value.style.left = selectedValueElement.value.getBoundingClientRect().left + 'px';
				} else {
					optionListElement.value.style.left =
						selectedValueElement.value.getBoundingClientRect().left -
						optionListElement.value.clientWidth +
						selectedValueElement.value.clientWidth +
						'px';
					optionListElement.value.style.top = selectedValueElement.value.getBoundingClientRect().bottom + 'px';
				}
				if (props.cover) {
					optionListElement.value.style.left = '0px';
					optionListElement.value.style.right = '0px';
					optionListElement.value.style.width = selectedValueElement.value.getBoundingClientRect().width + 40 + 'px';
					optionListElement.value.style.top = selectedValueElement.value.getBoundingClientRect().top + 'px';
					optionListElement.value.style.left =
						selectedValueElement.value.getBoundingClientRect().right - optionListElement.value.getBoundingClientRect().width + 'px';
				}
			}
		};

		const handleScroll = () => {
			if (optionListElement.value) {
				const scrollHeight = optionListElement.value.scrollHeight;
				const scrollTop = optionListElement.value.scrollTop;
				const clientHeight = optionListElement.value.clientHeight;
				if (scrollTop + clientHeight >= scrollHeight) {
					emit('update-list-func');
				}
			}
		};

		const handleResize = () => {
			setOptionListStyle();
		};

		watch(
			() => props.selectOptionParent,
			() => {
				parentElement.removeEventListener('scroll', handleScroll);
				parentElement.removeEventListener('scroll', handleResize);
				parentElement = props.selectOptionParent || window.document.body;
				parentElement.addEventListener('scroll', handleScroll);
				parentElement.addEventListener('scroll', handleResize);
			},
		);

		watch(
			() => optionListElement.value,
			() => {
				if (optionListElement.value) {
					setOptionListStyle();
					if (props.hasPagination && listOpened.value && optionListElement.value) optionListElement.value.addEventListener('scroll', handleScroll);
					else if (props.hasPagination && !listOpened.value && optionListElement.value)
						optionListElement.value.removeEventListener('scroll', handleScroll);
				}
			},
		);

		watch(route, () => {
			// 주소가 바뀌면 드롭다운 메뉴가 무조건 닫히도록
			closeOptionList();
		});

		watch(
			() => listOpened.value,
			() => {
				if (props.hasPagination && listOpened.value && optionListElement.value) optionListElement.value.addEventListener('scroll', handleScroll);
				else if (props.hasPagination && !listOpened.value && optionListElement.value)
					optionListElement.value.removeEventListener('scroll', handleScroll);
			},
		);

		onMounted(() => {
			window.addEventListener('resize', handleResize);
			window.addEventListener('scroll', handleResize);
			parentElement.addEventListener('scroll', handleResize);
			if (props.hasPagination && listOpened.value && optionListElement.value) optionListElement.value.addEventListener('scroll', handleScroll);
		});

		onUnmounted(() => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('scroll', handleResize);
			parentElement.removeEventListener('scroll', handleScroll);
			parentElement.removeEventListener('scroll', handleResize);
			if (props.hasPagination && !listOpened.value && optionListElement.value) optionListElement.value.removeEventListener('scroll', handleScroll);
		});

		return {
			t,
			listOpened,
			compOptionList,
			compShowArrow,
			compSelectedValue,
			selectedValueElement,
			optionListElement,
			comIsLoading,
			handleSelect,
			clickSelectBox,
			closeOptionList,
		};
	},
});
</script>

<template>
	<div class="apoc-select2" :class="{ disabled: disabled }">
		<div ref="selectedValueElement" class="selected-area" :class="{ placeholder: placeholder && !compSelectedValue }" @click="clickSelectBox">
			<div class="left-area">
				<apoc-svg v-if="compSelectedValue?.icon" class="option-icon" :src="compSelectedValue.icon" />
				<div class="selectedValue" v-html="compSelectedValue?.valueLabel || placeholder || ''" />
			</div>
			<div v-if="compShowArrow" class="select-arrow-down"><apoc-svg :class="{ active: listOpened }" :src="arrowImgSrc" /></div>
		</div>
		<teleport v-if="listOpened" to="#apoc-select-options">
			<div ref="optionListElement" class="option-list-area-wrapper" :class="optionClassList">
				<div v-if="showHeader" class="action-box">
					<div class="header-bar">
						<div class="bar"></div>
					</div>
				</div>
				<ul v-click-away="closeOptionList" class="option-list-area">
					<li v-if="!exceptPlaceholderFromList && placeholder" class="placeholder" @click="() => handleSelect()">
						<div>{{ placeholder }}</div>
					</li>
					<li
						v-for="option of optionList"
						:key="option.value"
						:class="{ hasIcon: option.icon, hasLine: option.value === '' }"
						@click="handleSelect(option)">
						<apoc-svg
							v-if="showCheck && !rightCheck"
							class="icon-check left"
							src="assets/images/common/icons/icon-check.svg"
							:class="{ 'opacity-0': option !== selectedValue }" />
						<div class="display-flex">
							<apoc-svg v-if="option.icon" :src="option.icon" />
							<div v-html="option.listLabel" />
						</div>
						<apoc-svg
							v-if="showCheck && rightCheck"
							class="icon-check right"
							src="assets/images/common/icons/icon-check.svg"
							:class="{ 'opacity-0': option !== selectedValue }" />
					</li>
					<!--					<li class="border"></li>-->
					<div class="border"></div>
					<li v-if="showCancel" class="cancel" @click="() => handleSelect()">
						<div>{{ t('common.cancel') }}</div>
						<apoc-svg class="icon-close" src="assets/images/common/icons/icon-close.svg" />
					</li>
					<li v-if="hasPagination && comIsLoading" class="loader-area cursor-auto">
						<div class="loader cursor-auto"></div>
					</li>
				</ul>
			</div>
		</teleport>
	</div>
</template>

<style></style>
