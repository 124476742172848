<script lang="ts">
import { ref, defineComponent, computed, onMounted, watch } from 'vue';
import AppConfig, { APP_ENV_TYPE } from "@/constants";
import { ApocMegaMenuConfig, ENV, LOCATION, SERVICE_TYPE, THEME, LANG } from '@apoc/apoc-mega-menu';
import '@apoc/apoc-mega-menu/dist/style.css';
import { useRouter } from 'vue-router';
import { loadLocalData, removeLocalData, saveLocalData } from '@/utils/common-util';
import { ssoLogin } from '@/utils/sso-login';
import { initStore } from '@/stores/store-manager';
import { getUserInfo } from '@/api/user';
import { loadApiGauge } from '@/utils/utils';
import { LOADING_TYPE } from '@/types/index';
import { getApiClient } from '@/utils/apiClient';

export default defineComponent({
	name: 'App',
	setup() {
		const router = useRouter();
		const token = computed(() => loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN));
		const user = computed(() => loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER));
		const lang = computed(() => (loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG) === 'ko' ? LANG.KO : LANG.EN)); // 언어 옵션
		const storeManager = initStore();
		const apiClient = computed(() => getApiClient(AppConfig.API_SERVER, storeManager));

		// @apoc-mega-menu
		const isLogin = ref(false);

		// @apoc-mega-menu config
		// config를 ref로 싸면 실시간으로 변화를 줄 수 있어요!
		const config = ref<ApocMegaMenuConfig>({
			env: AppConfig.ENV as ENV,
			serviceType: SERVICE_TYPE.PLAY,
			sideBarLocation: LOCATION.LEFT,
			theme: THEME.LIGHT,
			lang: lang.value,
			searchBarPlaceHolder: '',
			alwaysSearchbarOpen: false,
		});

		// @apoc-mega-menu search bar enter event
		const onEnterKeyword = (keyword: string | undefined) => {
			router.push({ path: '/search', query: { keyword } });
		};

		// @apoc-mega-menu change lang event
		const onChangeLang = (lang: LANG) => {
			saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, lang);
			window.location.reload();
		};

		// @apoc-mega-menu login event
		const onClickLogin = () => {
			ssoLogin();
		};


		const onClickSearch = (v: boolean) => {
			// console.log(`onClickSearch `, v);
			storeManager.stateStore.setIsOpenSearchBar(v);
		};

		const updateUserInfo = () => {
			getUserInfo(apiClient.value, {})
				.then(res => {
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) {
							saveLocalData(AppConfig.KEYS.CONST.LOGIN_USER, JSON.stringify(res.data));
						}
					} else if (res.resultCode === 91) {
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
						storeManager.dataStore.setAuthToken('');
					}
				})
				.catch(e => {
					removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
					removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
					storeManager.dataStore.setAuthToken('');
				});
		};

		watch(()=>storeManager.dataStore.authToken, ()=>{
			isLogin.value = !!storeManager.dataStore.authToken;
		}, {immediate: true})

		onMounted(() => {
			updateUserInfo();
		});

		return {
			user,
			token,
			config,
			isLogin,
			onEnterKeyword,
			onChangeLang,
			onClickLogin,
			onClickSearch
		};
	},
});
</script>

<template>
	<apoc-mega-menu class="apoc-mega-menu" :config="config" :is-login="isLogin" @on-enter-keyword="onEnterKeyword"
		@on-change-lang="onChangeLang" @on-click-login="onClickLogin" @on-click-search="onClickSearch">
	</apoc-mega-menu>
</template>
