import { zlibSync } from 'fflate';
import lzString from 'lz-string';
import { AttachFileEntity } from '@/api/model/attach.file.entity';
import AppConfig from '../constants';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useRoute } from 'vue-router';
import copy from 'copy-to-clipboard';
import { BROWSER_TYPE, MOBILE_OS_TYPE } from '@/types';

export enum ShareType {
  COPY_LINK = 'copyLink',
  COPY_IFRAME = 'copyIframe',
}

export const formatNumberWithCommas = (num: number) => {
  // 정규식을 활용하여 천 단위로 쉼표를 추가
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function saveSessionData(key: string, val: string): void {
  const storage = window.sessionStorage;
  if (storage) {
    try {
      storage.setItem(key, lzString.compressToUTF16(val));
    } catch (e) {
      console.error('Storage Full ... clean old data...');
      for (const k in storage) {
        if (k.indexOf('DATA_MESSAGE_DETAIL_') > -1) {
          storage.removeItem(k);
        }
      }
      storage.setItem(key, lzString.compressToUTF16(val));
    }
  }
}

export function loadSessionData(key: string): string | null {
  const storage = window.sessionStorage;
  if (storage) {
    const keyValue = storage.getItem(key);
    if (keyValue) return lzString.decompressFromUTF16(keyValue);
  }
  return null;
}

export function removeSessionData(key: string): void {
  const storage = window.sessionStorage;
  if (storage) {
    storage.removeItem(key);
  }
}

export const generateShareLink = (type: ShareType, contentIdx: string, canvasUuid?: string): void => {
  if (type === ShareType.COPY_LINK) {
    let url = `${AppConfig.FRONT_HOST}direct/` + contentIdx;
    if (canvasUuid) url += `?page=${canvasUuid}`;
    copy(url);
  } else if (type === ShareType.COPY_IFRAME) {
    let url = `${AppConfig.FRONT_HOST}direct/` + contentIdx;
    if (canvasUuid) url += `?page=${canvasUuid}`;
    copy(
      `<iframe width='560' height='315' src='${url}' class='crack-player-big-mode' frameborder='0' title='crack-player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`,
    );
  }
};

export function generateShortShareLink(shortUrl: string, noCopy?: boolean) {
  const url = `${AppConfig.FRONT_HOST}d/` + shortUrl;
  if (!noCopy) copy(url);
  return url;
}

export function doCopyToDashboard(v: string) {
  copy(v);
}

//콘텐츠 상세 페이지 url 만들기
export const generateDetailShareLink = (id: string): void => {
  const url = `${AppConfig.FRONT_HOST}content/` + id;
  copy(url);
};

export const getDiffTimeFormat = (time: Date | string | null | undefined) => {
  const { t } = useI18n();
  const diffTime = moment().diff(moment(time));
  const duration = moment.duration(diffTime);
  let years, months, days, hrs, mins, secs;

  // diffTime = 차이 시간
  const dayN = diffTime / 86400000;
  if (dayN >= 1) {
    if (dayN >= 365) {
      years = (dayN / 365).toFixed(0);
    } else if (dayN < 365 && dayN >= 30) {
      months = (dayN / 30).toFixed(0);
    } else {
      days = dayN.toFixed(0);
    }
  } else {
    hrs = duration.hours();
    mins = duration.minutes();
    secs = duration.seconds();
  }

  if (years) {
    return `${years + t('layout.years') + ' ' + t('layout.ago')}`;
  }
  if (months) {
    return `${months + t('common.months') + ' ' + t('layout.ago')}`;
  }
  if (days) {
    return `${days + t('layout.days') + ' ' + t('layout.ago')}`;
  }
  if (hrs) {
    return `${hrs + t('layout.hrs') + ' ' + t('layout.ago')}`;
  }
  if (mins) {
    return `${mins + t('layout.mins') + ' ' + t('layout.ago')}`;
  }
  if (secs) {
    return `${secs + t('layout.secs') + ' ' + t('layout.ago')}`;
  } else if (secs === 0) {
    return t(`layout.justNow`);
  }
  return '';
};

export const getTotalPageCount = (compRowCount: number, totalCount: number): number => {
  const rowCount = compRowCount ?? AppConfig.KEYS.MORE_LIMIT.DEFAULT;
  return Math.ceil(totalCount / rowCount);
};

export const isHaveNextPage = (compRowCount: number, pageNo: number, totalCount: number): boolean => {
  const currentPageCount = pageNo ?? AppConfig.KEYS.FIRST_PAGE_COUNT;
  return currentPageCount < getTotalPageCount(compRowCount, totalCount);
};

export const getWebp = (savedName: string) => {
  const originUrl = savedName;
  const fileLen = originUrl.length;
  const lastDot = originUrl.lastIndexOf('.');
  const fileExt = originUrl.substring(lastDot, fileLen);
  return originUrl.replace(fileExt, '.webp');
};

export const getQueryString = (query: string) => {
  const route = useRoute();
  if (Object.prototype.hasOwnProperty.call(route.query, query)) {
    return route.query[query as string] as string;
  } else {
    return;
  }
};

export const getThumbNail = (savedName: string) => {
  const originUrl = savedName;
  const fileLen = originUrl.length;
  const lastDot = originUrl.lastIndexOf('.');
  const fileExt = originUrl.substring(lastDot, fileLen);
  const thumbNail = originUrl.replace(fileExt, '');
  return thumbNail + '.webp';
};

export const setLanguage = (lang: string) => {
  if (loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)) {
    return;
  }
  switch (lang) {
    case 'ko':
    case 'ko-KR':
      saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, 'ko');
      break;
    case 'en-US':
      saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, 'en');
      break;
    default:
      saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, 'en');
      break;
  }
};

export const getImgUrl = (key: string | null | undefined | AttachFileEntity, type?: string, isVertical?: boolean) => {
  const defaultThumbnailUrl = isVertical ? '/assets/images/layout/defaultThumbnail_vertical.webp' : '/assets/images/layout/defaultThumbnail.webp';

  if (key) {
    if (typeof key === 'string') {
      const imgInfo = JSON.parse(key);
      if (imgInfo) {
        if (type === 'origin') {
          return `${AppConfig.FILE_SERVER}${getWebp(imgInfo[0].saveFileName)}`;
        }
        // 외부 썸네일 대응용
        if (imgInfo[0].saveFileName.indexOf('https://') > -1) {
          return imgInfo[0].saveFileName;
        } else {
          return `${AppConfig.FILE_SERVER}${getThumbNail(imgInfo[0].saveFileName)}`;
        }
      } else {
        return defaultThumbnailUrl;
      }
    } else {
      return defaultThumbnailUrl;
    }
  }
  return defaultThumbnailUrl;
};

export function getUuid(): string {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = ((dt + Math.random() * 16) % 16) | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid.replace(/-/gi, '').toLowerCase();
}

export const getMobileOS = (): MOBILE_OS_TYPE => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return MOBILE_OS_TYPE.ANDROID;
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return MOBILE_OS_TYPE.IOS;
  }
  return MOBILE_OS_TYPE.OTHER;
};

/**
 * 브라우저 타입 조회
 */
export const getBrowserType = () => {
  let type: BROWSER_TYPE | undefined = undefined;
  if (typeof window !== 'undefined') {
    const agent = navigator.userAgent.toLowerCase();
    // !!!체크해야하는 순서로 나열되어 있음!!!
    if (agent.includes(BROWSER_TYPE.KAKAO)) {
      type = BROWSER_TYPE.KAKAO;
    } else if (agent.includes(BROWSER_TYPE.NAVER)) {
      type = BROWSER_TYPE.NAVER;
    } else if (agent.includes(BROWSER_TYPE.INSTA)) {
      type = BROWSER_TYPE.INSTA;
    } else if (agent.includes(BROWSER_TYPE.WHALE)) {
      type = BROWSER_TYPE.WHALE;
    } else if (agent.includes(BROWSER_TYPE.SAMSUNG)) {
      type = BROWSER_TYPE.SAMSUNG;
    } else if (agent.includes(BROWSER_TYPE.EDGE)) {
      type = BROWSER_TYPE.EDGE;
    } else if (agent.includes(BROWSER_TYPE.QQ)) {
      type = BROWSER_TYPE.QQ;
    } else if (agent.includes(BROWSER_TYPE.CHROME)) {
      type = BROWSER_TYPE.CHROME;
    } else if (agent.includes(BROWSER_TYPE.FIREFOX)) {
      type = BROWSER_TYPE.FIREFOX;
    } else if (agent.includes(BROWSER_TYPE.SAFARI)) {
      type = BROWSER_TYPE.SAFARI;
    } else if (agent.match(/FBAN|FBAV/i)) {
      type = BROWSER_TYPE.FACEBOOK;
    } else if (agent.includes(BROWSER_TYPE.TWITTER)) {
      type = BROWSER_TYPE.TWITTER;
    }
  }
  return type;
};

export function saveLocalData(key: string, val: string): void {
  if (typeof window !== 'undefined') {
    const storage = window.localStorage;
    if (storage) {
      try {
        storage.setItem(key, lzString.compressToUTF16(val));
      } catch (e) {
        console.error('Storage Full ... clean old data...');
        for (const k in storage) {
          if (k.indexOf('DATA_MESSAGE_DETAIL_') > -1) {
            storage.removeItem(k);
          }
        }
        storage.setItem(key, lzString.compressToUTF16(val));
      }
    }
  }
}

export function loadLocalData(key: string): string | null {
  if (typeof window !== 'undefined') {
    const storage = window.localStorage;
    if (storage) {
      const keyValue = storage.getItem(key);
      if (keyValue) return lzString.decompressFromUTF16(keyValue);
    }
  }
  return null;
}

export function removeLocalData(key: string): void {
  if (typeof window !== 'undefined') {
    const storage = window.localStorage;
    if (storage) {
      storage.removeItem(key);
    }
  }
}

/**
 * targetEntity 에 포함된 key에 value 적용
 * @param targetEntity
 * @param obj
 */
export const setEntityParameters = (targetEntity: any, obj: any) => {
  const keyList = Object.keys(targetEntity);
  for (const key of keyList) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      targetEntity[key] = obj[key];
    }
  }
};

/**
 * 웹앱 대응 라우터 이동
 * @param url
 * @param isExternal
 */
export function pushView(url: string, isExternal?: boolean, query?: string): void {
  if (typeof url === 'undefined' || ('' + url).trim() === '') {
    window.alert('주소를 찾을 수 없습니다.');
    return;
  }
  if (!isExternal && url.indexOf('http') < 0) {
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + '//' + getUrl.host + getUrl.pathname;
    url = baseUrl;
    if (query) url = url + '?' + query;
  }
  // @ts-ignore
  if (window.apocApp && window.apocApp.pushView) {
    // @ts-ignore
    window.apocApp.pushView(url);
    // @ts-ignore
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.pushView) {
    // @ts-ignore
    window.webkit.messageHandlers.pushView.postMessage(url);
  } else {
    window.location.href = url;
  }
}
export function convertStringToUint8Array(v: string): Uint8Array {
  return lzString.compressToUint8Array(v);
}
export function compressZlib(v: string): Uint8Array {
  return zlibSync(convertStringToUint8Array(v), { level: 9 });
}

export function changeAddComma(v: string | number) {
  const srt = String(v);
  return srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// function copy(url: string) {
// 	throw new Error('Function not implemented.');
// }

export const removeJsonLd = async () => {
  const jsonLdScripts = document.querySelectorAll('script[type="application/ld+json"]');
  jsonLdScripts.forEach(script => script.remove());
};
