// 브라우저 종류 (체크해야하는 순서로 나열되어 있음)
export enum BROWSER_TYPE {
	KAKAO = 'kakao',
	NAVER = 'naver',
	INSTA = 'instagram',
	WHALE = 'whale',
	SAMSUNG = 'samsungbrowser',
	EDGE = 'edge',
	QQ = 'qqbrowser',
	CHROME = 'chrome',
	FIREFOX = 'firefox',
	SAFARI = 'safari',
	FACEBOOK = 'facebook',
	TWITTER = 'twitter',
}

// 허용하지 않는 인앱 브라우저
export const notAllowInappList: BROWSER_TYPE[] = [BROWSER_TYPE.KAKAO, BROWSER_TYPE.NAVER, BROWSER_TYPE.INSTA, BROWSER_TYPE.FACEBOOK, BROWSER_TYPE.TWITTER];

// 허용하는 브라우저 리스트
export const allowBrowserList: BROWSER_TYPE[] = [
	BROWSER_TYPE.CHROME,
	BROWSER_TYPE.SAFARI,
	BROWSER_TYPE.FIREFOX,
	BROWSER_TYPE.SAMSUNG,
	BROWSER_TYPE.EDGE,
	BROWSER_TYPE.WHALE,
	BROWSER_TYPE.QQ,
];