<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { LOADING_TYPE } from '@/types';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
export default defineComponent({
	name: 'LoadingTypeRoll',

	props: {
		loadingType: {
			type: Number,
			required: true,
			default: 0,
		},
		title: {
			type: String,
			required: true,
			default: '',
		},
		des: {
			type: String,
			required: false,
			default: '',
		},
		progress: {
			type: Number,
			required: true,
			default: 0,
		},
	},
	setup() {
		onMounted(() => {
			lockBackgroundScroll();
		});

		onUnmounted(() => {
			unLockBackgroundScroll();
		});

		return {
			LOADING_TYPE,
		};
	},
});
</script>

<template>
	<div class="loading-container-roll">
		<div class="title-container-roll">
			<span class="loading-progress-roll">{{ progress }}%</span>
		</div>
		<div class="loading-bar-container-roll">
			<div class="loading-bar-roll">
				<div class="innerbar-roll" :style="{ width: progress + '%' }"></div>
				<div class="image-overlay">
					<img
						class="loading-fur-roll"
						:style="{ left: progress - 5 + '%', transform: 'rotate(' + progress + 5 + 'deg)' }"
						src="/assets/images/loading/icon-fur.webp" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
