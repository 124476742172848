import { getEmitter } from '@/utils/event-emitter-utils';
import { LOADING_TYPE } from '@/types';
import { initStore } from '@/stores/store-manager';

/**
 * HTML Entity 값을 원래 입력값으로 변경
 * @param str
 */
export const decodeHTMLEntities = (str: string): string => {
	return str
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&')
		.replace(/&quot;/g, '"')
		.replace(/&apos;/g, "'");
};

/**
 * body 스크롤 막기
 */
export const lockBackgroundScroll = () => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		window.document.body.style.overflow = 'hidden';
	}
};

/**
 * body 스크롤 풀기
 */
export const unLockBackgroundScroll = () => {
	// window.document.body.style.overflow = 'auto';
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		window.document.body.style.removeProperty('overflow');
	}
};

/**
 * 특정 조건에서 로딩이 완료되지 않은 경우
 * 로딩바 게이지 채우기
 */
export const loadApiGauge = (type: LOADING_TYPE, des: string, title: string) => {
	// 로드가 될 때마다 채울 게이지 계산
	const storeManager = initStore();
	if (storeManager.dataStore.pageApiTotalCount > 0 && storeManager.dataStore.loadedCount > 0) {
		let gauge = (100 / storeManager.dataStore.pageApiTotalCount) * storeManager.dataStore.loadedCount;
		gauge = gauge >= 100 ? 100 : Math.floor(gauge);
		if (storeManager.stateStore.loadingState) {
			getEmitter().emit('LOADING_STATE', {
				loadingType: type,
				des: des,
				title: title,
				progress: gauge,
			});
			if (gauge >= 100) {
				storeManager.dataStore.setPageApiTotalCount(0);
				storeManager.dataStore.setLoadedCount(0);
				storeManager.stateStore.setLoadingState(false);
			}
		}
	}
};
