import { TRACKER_TYPE_CODE } from '@/types';

class TrackerUtil {
	private essentialTypeList = [
		TRACKER_TYPE_CODE.RMVRESR,
		TRACKER_TYPE_CODE.ADDASSET,
		TRACKER_TYPE_CODE.CONTSAVE,
		TRACKER_TYPE_CODE.CONTSHAR,
		TRACKER_TYPE_CODE.CONTDEL,
	]; //TODO: 임시로 지정 - 필수 수집 타입 리스트

	constructor() {
		// @ts-ignore
		if (typeof window !== 'undefined' && !window.tracker) (window as any).tracker = new window.ApocTracker('APOC');
	}

	/**
	 *
	 * @param uuid userId
	 */
	setLoginUserId(uuid?: string) {
		(window as any)?.tracker.setLoginUserId(uuid);
	}
	/**
	 * sendEvent
	 * @param type 수집코드
	 * @param data
	 */
	sendEvent(type: TRACKER_TYPE_CODE, data?: any) {
		// TODO 현재는 조건 없음
		(window as any)?.tracker.sendEvent(type, data);
	}
}

let trackerUtil: TrackerUtil | undefined;
export const getTrackerUtil = () => {
	if (!trackerUtil) trackerUtil = new TrackerUtil();
	return trackerUtil;
};
