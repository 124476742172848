<script setup lang="ts">
import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { RESOLUTION_TYPE } from '@/stores/data-store';
import { initStore } from '@/stores/store-manager';
import { getApiClient } from '@/utils/apiClient';
import { loadLocalData } from '@/utils/common-util';
import { inappCoverEnable, notAllowInapp } from '@/utils/inapp-view-util';
import { getMsTeams } from '@/utils/microsoft-teams';
import { useHead } from '@unhead/vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getSsoUser, ssoLogin } from './utils/sso-login';
import ApocMegaMenu from './components/header/ApocMegaMenu.vue';
import InappNoticePage from '@/views/InappNoticePage.vue';
import LoadingManager from '@/components/layout/common/LoadingManager.vue';
import PopupManager from '@/components/popup/PopupManager.vue';
import CopyToastPopup from '@/components/popup/CopyToastPopup.vue';
import MainFooter from '@/components/footer/MainFooter.vue';

const { t, locale } = useI18n();
const storeManager = initStore();
const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
const route = useRoute();
const router = useRouter();
const isAuthPage = ref<boolean>(false);
const isSsoLoginPage = ref<boolean>(false);
const parentHost = (window.location != window.parent.location) ? document.referrer : document.location.href
const apocPlayHost = ref<boolean>(parentHost.startsWith(AppConfig.FRONT_HOST)); //직접 호출인지 iframe 호출인지 체크
const inAppBrowser = ref<boolean>(notAllowInapp()); //인앱브라우저 체크

const swUpdateBarExposeList = ['Contents Detail', 'Contents Direct', 'Contents Short Direct']; // sw 업데이트바가 노출되어야하는 route.name
const showAuthorSwBar = ref(false); // author pwa에서 update가 필요한경우
const directContents = ref<boolean>(false);
const contentViewer = ref<boolean>(false); // 콘텐츠를 볼 수 있는 화면
const showInappNotice = ref<boolean>(true); //인앱 화면 노출
const isMainPage = ref<boolean>(false);
const isFullPage = ref<boolean>(false);
const isSearchPage = ref<boolean>(false);
const isTossDirectPage = ref<boolean>(false);
const isNotAllowBrowser = ref<boolean>(false);
const isBgShow = computed(() => isMainPage.value || isSearchPage.value);

useHead({
  title: `${t('seo.title')}`,
  meta: [
    {
      name: 'description',
      content: `${t('seo.description')}`,
    },
  ],
});

// 삼성 물산 서포터즈 링크 이동용 임시 작업
const url = new URL(window.location.href);
const isTempSamsungRedirect = computed(() => url.pathname === '/2410/supporters');
if (isTempSamsungRedirect.value) {
  window.location.href = 'https://forms.gle/BNrErz3Vs3SBGm4E9';
}

const setWindowResolution = () => {
  let device = RESOLUTION_TYPE.PC;
  if (window.innerWidth <= 425) {
    device = RESOLUTION_TYPE.S_MOBILE;
  } else if (window.innerWidth <= 767) {
    device = RESOLUTION_TYPE.MOBILE;
  } else if (window.innerWidth <= 1023) {
    device = RESOLUTION_TYPE.TABLET;
  } else if (window.innerWidth <= 1280) {
    device = RESOLUTION_TYPE.S_PC;
  }
  storeManager.dataStore.setResolution(device);
};

/**
 * Third party 상태 저장
 */
const setThirdPartyType = () => {
  // cafe 24
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('tk');
  if (
    // msTeams app 에서 호출했을시
    window.location.ancestorOrigins &&
    window.location.ancestorOrigins.length > 0 &&
    window.location.ancestorOrigins[0].indexOf('teams.microsoft') > -1
  ) {
    storeManager.commonStore.setThirdPartyType(THIRD_PARTY_TYPE.MS_TEAMS);
    // init ms teams
    getMsTeams();
  } else if (code) {
    window.location.href = AppConfig.FRONT_HOST + `cafe24?code=${code}`;
  } else {
    storeManager.commonStore.setThirdPartyType(THIRD_PARTY_TYPE.NONE);
  }
  // window.alert(storeManager.stateStore.thirdPartyType);
};

const handleResize = () => {
  setWindowResolution();
};

const hasRouteName = computed(() => {
  return (
    route.name !== '' &&
    route.name !== 'Contents Direct' &&
    route.name !== 'IndependenceHall' &&
    route.name !== 'Contents Short Direct' &&
    route.name !== 'IndependenceHallSurvey_1' && // survey 푸터 막기
    route.name !== 'IndependenceHallSurvey_2' &&
    route.name !== 'IndependenceHall2' &&
    route.name !== 'IndependenceHall3' &&
    route.name !== 'Reservation' &&
    route.name !== 'apocVip' &&
    typeof route.name !== 'undefined'
  );
});

const handleLocalStorage = () => {
  if (!(loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER) && loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN))) {
    storeManager.dataStore.setAuthToken('');
  }
};

/**
 * 저작도구에서 넘어온 message handler
 * @param e
 */
const handleMessageReceived = (e: MessageEvent) => {
  if (AppConfig.AUTHOR_HOST.indexOf(e.origin) > -1) {
    if (e.data.needSwUpdate) {
      // 저작도구에서 Pwa 업데이트를 요청한 경우
      if (route.name && swUpdateBarExposeList.indexOf(route.name as string) > -1) {
        // route.name이 특정페이지에 있을 경우
        const bodyEl = window.document.getElementsByTagName('body');
        if (bodyEl && bodyEl.length > 0) bodyEl[0].classList.add('sw-need-update');
        showAuthorSwBar.value = true;
      }
    } else if (e.data.doSwUpdate) {
      // 업데이트 실행 및 완료한경우?
      const bodyEl = window.document.getElementsByTagName('body');
      if (bodyEl && bodyEl.length > 0) bodyEl[0].classList.remove('sw-need-update');
      showAuthorSwBar.value = false;
    }
  }
};

// Ms app 에서 저작도구에서 넘어가는 return
const MsTeamsAuth = () => {
  getMsTeams()
    .getContext()
    .then((res) => {
      if (res.page.subPageId) {
        const t = JSON.parse(res.page.subPageId);
        if (!t) return;
        if (t.needLogin) {
          ssoLogin();
        } else if (t.logout) {
          // 저작도구 로그아웃 성공시
        }
      }
    });
};

watch(
  () => route.path,
  () => {
    isFullPage.value = route.path.indexOf('reservation') > -1;
    isMainPage.value = route.path === '/';
    isSearchPage.value = route.path === '/search';
    isSsoLoginPage.value = route.path === '/login/success';
    isTossDirectPage.value = route.path === '/2410/toss/d' || route.path === '/2410/toss/d2' || route.path === '/2410/toss/d/success';
    window.scrollTo(0, 0);
  },
);

const updateManifest = async () => {
  const domManifest = document.querySelector('link[rel=manifest]');
  if (domManifest) {
    const result = await fetch('/assets/favicon/site.webmanifest');
    if (result) {
      const manifest = await result.json();
      manifest.start_url = window.location.href;
      domManifest.setAttribute('href', `data:application/json;base64,${btoa(JSON.stringify(manifest))}`);
    }
  }
};

onMounted(async () => {
  window.addEventListener('storage', handleLocalStorage);

  await updateManifest();

  // sso 자동 로그인
  if (route.path !== '/sso/logout') {
    getSsoUser(storeManager);
  }

  directContents.value =
    (('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('direct') > -1 &&
      !(('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('login') > -1)) ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('IndependenceHall') > -1 ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('reservation') > -1 ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('apocVip') > -1 ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('heycomehere') > -1 ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').substring(0, 2) === 'd/' ||
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('survey_') > -1; // survey일때 헤더 안보이는 조건 추가

  contentViewer.value = 
  ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').substring(0, 2) === 'd/' || 
  ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('content/') > -1;

  if (contentViewer.value) {
    let key =  route.path;
    key = key.replace('/d/', '');
    key = key.replace('/content/', '');
    showInappNotice.value = inappCoverEnable(key);
  }

  // ('' + window.location.href).replace(AppConfig.FRONT_HOST, '')

  storeManager.stateStore.setDirectContents(directContents.value);
  isAuthPage.value =
    ('' + window.location.href).replace(AppConfig.FRONT_HOST, '').toLowerCase().indexOf('auth') > -1 &&
    !(('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('login') > -1);

  window.addEventListener('message', handleMessageReceived);
  window.addEventListener('resize', handleResize);

  setWindowResolution();

  const url = new URL(window.location.href);
  if (AppConfig.ENV === APP_ENV_TYPE.PROD && url.host !== 'www.apoc.day') window.location.href = 'https://www.apoc.day';

  // third party 호출 여부
  setThirdPartyType();

  // ms 호출시 대응
  if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.MS_TEAMS) MsTeamsAuth();

  isSsoLoginPage.value = route.path === '/login/success';
  isFullPage.value = route.path.indexOf('reservation') > -1 || route.path.indexOf('apocVip') > -1;
  isMainPage.value = route.path === '/';
  isSearchPage.value = route.path === '/search';
  isTossDirectPage.value =
    route.path === '/2410/toss/d' || route.path === '/2410/toss/d2' || route.path === '/2410/toss/d/success' || route.path === '/2410/toss/d/failed';
});

onUnmounted(() => {
  window.removeEventListener('message', handleMessageReceived);
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('storage', handleLocalStorage);
});
</script>
<template>
  <inapp-notice-page v-if="(contentViewer && apocPlayHost && inAppBrowser && showInappNotice)" />
	<div>
		<loading-manager />
	</div>
	<div v-show="isBgShow" class="app-bg"></div>
	<!--  셀렉트 옵션 리스트 영역-->
	<div id="apoc-select-options"></div>
	<popup-manager v-if="!isAuthPage"></popup-manager>
	<copy-toast-popup v-if="storeManager.stateStore.popupCopyState && !isAuthPage" />
	<!-- <MegaMenuPlay v-if="!isSsoLoginPage && !isAuthPage && !directContents && !isTempSamsungRedirect && !isTossDirectPage" /> -->
	<apoc-mega-menu
		v-if="!isSsoLoginPage && hasRouteName && !isAuthPage && !directContents && !isTossDirectPage && (apocPlayHost)" />
	<!-- <MegaMenu /> -->
	<div v-if="!isNotAllowBrowser && !(contentViewer && apocPlayHost && inAppBrowser && showInappNotice)" class="page" :class="{
    'no-padding-top': !apocPlayHost && contentViewer,
		'full-page': isFullPage,
		'search-bar-open-page': storeManager.stateStore.isOpenSearchBar,
	}">
		<router-view :key="$route.path" />
	</div>
	<main-footer v-if="hasRouteName && !isAuthPage && !directContents && !isTossDirectPage" />
	<div v-if="!directContents && storeManager.commonStore.isLoading" ref="axiosWrapper" class="axios-loading">
		<div ref="loader" class="loader"></div>
	</div>
</template>

<style lang="scss">
@import 'assets/css/app.scss';

#apoc-mega-menu {
	div,
	a,
	ul,
	li {
		font: inherit;
	}
}
</style>
