<script lang="ts">
import { UserFollowerNoticeConfigEntityDto } from '@/api/dto/notice.dto';
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { deleteUserFollowerNoticeConfig, insertUserFollowerNoticeConfig } from '@/api/notice';
import { deleteFollowerUser, getUserList, insertFollowerUser } from '@/api/user';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import ApocLink from '@/components/common/ApocLink.vue';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { STATE_YN, USER_FOLLOWER_NOTICE_TYPE_CODE } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { ShareType, loadLocalData } from '@/utils/common-util';
import { ssoLogin } from '@/utils/sso-login';
import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'FollowModal',
	computed: {
		ShareType() {
			return ShareType;
		},
	},
	components: { ApocLink, ApocImageSet },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const router = useRouter();
		const loginUserInfo = ref<UserInfoEntityDto | null>(null);
		const followCnt = ref<number>(0);
		const userData = ref<UserInfoEntityDto | null>(null);

		const changeAlarmType = async (state: USER_FOLLOWER_NOTICE_TYPE_CODE) => {
			try {
				if (userData.value?.regrUserIdx) {
					const param = new UserFollowerNoticeConfigEntityDto();
					switch (state) {
						case USER_FOLLOWER_NOTICE_TYPE_CODE.ON:
							param.userFollowerNoticeTypeCode = USER_FOLLOWER_NOTICE_TYPE_CODE.ON;
							param.targetUserIdx = userData.value?.regrUserIdx;
							insertUserFollowerNoticeConfig(apiClient, param).then(res => {
								if (res.resultCode !== 0) {
									window.alert(t('msg.' + res.resultMsg));
								} else {
									if (userData.value) {
										userData.value.alarmType = state;
										closePopup();
										window.location.reload();
									}
								}
							});
							break;
						case USER_FOLLOWER_NOTICE_TYPE_CODE.OFF:
							param.userFollowerNoticeTypeCode = USER_FOLLOWER_NOTICE_TYPE_CODE.ON;
							param.targetUserIdx = userData.value?.regrUserIdx;
							deleteUserFollowerNoticeConfig(apiClient, param).then(res => {
								if (res.resultCode !== 0) {
									window.alert(t('msg.' + res.resultMsg));
								} else {
									if (userData.value) {
										userData.value.alarmType = state;
										closePopup();
										window.location.reload();
									}
								}
							});
							break;
					}
				}
			} catch (err) {
				window.alert(err);
			}
		};

		const isFollowing = (): boolean => {
			return userData.value?.followerUser === STATE_YN.Y;
		};

		const onClickAlarm = (value: USER_FOLLOWER_NOTICE_TYPE_CODE) => {
			changeAlarmType(value);
		};

		const onClickFollow = () => {
			if (userData.value && loginUserInfo.value) {
				if (isFollowing()) {
					if (window.confirm(t('msg.ALERT_CANCEL_FOLLOWING'))) {
						doUnFollowing();
					}
				} else {
					doFollowing();
				}
			} else {
				window.alert(t('msg.ALERT_JUST_LOGIN'));
				ssoLogin();
			}
		};

		const setFollowerUser = (v: STATE_YN | null): void => {
			if (userData.value && v) {
				userData.value.followerUser = v;
				userData.value.followerCount = v === STATE_YN.Y ? Number(userData.value.followerCount) + 1 : Number(userData.value.followerCount) - 1;
			}
		};

		const doUnFollowing = async () => {
			try {
				if (userData.value?.userIdx) {
					await deleteFollowerUser(apiClient, {
						targetUserIdx: userData.value.userIdx,
					}).then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.' + res.resultMsg));
						} else {
							let decrease = followCnt.value;
							decrease--;
							followCnt.value = decrease;
							setFollowerUser(STATE_YN.N);
							closePopup();
							window.location.reload();
						}
					});
				}
			} catch (err) {
				window.alert(err);
			}
		};

		const doFollowing = async () => {
			try {
				if (userData.value?.userIdx) {
					await insertFollowerUser(apiClient, { targetUserIdx: userData.value.userIdx }).then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.' + res.resultMsg));
						} else {
							let increase = followCnt.value;
							increase++;
							followCnt.value = increase;
							setFollowerUser(STATE_YN.Y);
							closePopup();
							window.location.reload();
						}
					});
				}
			} catch (err) {
				window.alert(err);
			}
		};

		const getLoginUserInfo = (): void => {
			const data = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (data) {
				loginUserInfo.value = JSON.parse(data);
			}
		};

		//팝업 닫기
		const closePopup = (): void => {
			// storeManager.dataStore.setPopupContentId('');
			// storeManager.dataStore.setPopupShortUrl('');
			// storeManager.dataStore.setContentsTargetIdx('');
			// storeManager.dataStore.setContentsTargetInfo(undefined);
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
			getLoginUserInfo();
			// creator 페이지 유저 정보 조회
			getUserList(apiClient, { userIdx: storeManager.dataStore.followUserUuid }).then(res => {
				if (res.resultCode === 0) {
					if (res.data) {
						userData.value = res.data[0];
					}
				}
			});
			if (userData.value?.followerCount) followCnt.value = userData.value?.followerCount;
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			onClickAlarm,
			onClickFollow,
			USER_FOLLOWER_NOTICE_TYPE_CODE,
		};
	},
});
</script>
<template>
	<div class="contents-select-modal follow-modal">
		<div class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link href="#" @click="onClickAlarm(USER_FOLLOWER_NOTICE_TYPE_CODE.ON)">
				<apoc-image-set class="origin-img" src="/assets/images/common/icons/icon-bell.svg" />
				<apoc-image-set class="hover-img" src="/assets/images/common/icons/bell.svg" />
				<span>
					{{ t('layout.contentDetail.alarm') }}
				</span>
			</apoc-link>
			<apoc-link href="#" @click="onClickAlarm(USER_FOLLOWER_NOTICE_TYPE_CODE.OFF)">
				<apoc-image-set class="origin-img" src="/assets/images/common/icons/bell-slash.svg" />
				<apoc-image-set class="hover-img" src="/assets/images/common/icons/bell-slash-purple.svg" />
				<span>
					{{ t('layout.contentDetail.noAlarm') }}
				</span>
			</apoc-link>
			<apoc-link href="#" @click="onClickFollow">
				<apoc-image-set class="origin-img" src="/assets/images/common/icons/user-slash.svg" />
				<apoc-image-set class="hover-img" src="/assets/images/common/icons/user-slash-purple.svg" />
				<span>
					{{ t('layout.contentDetail.noFollow') }}
				</span>
			</apoc-link>
		</div>
	</div>
</template>
