<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { LOADING_TYPE } from '@/types';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
export default defineComponent({
	name: 'LoadingTypeBounce',

	props: {
		loadingType: {
			type: Number,
			required: true,
			default: 0,
		},
		title: {
			type: String,
			required: true,
			default: '',
		},
		des: {
			type: String,
			required: false,
			default: '',
		},
		progress: {
			type: Number,
			required: true,
			default: 0,
		},
	},

	setup() {
		onMounted(() => {
			lockBackgroundScroll();
		});

		onUnmounted(() => {
			unLockBackgroundScroll();
		});

		return {
			LOADING_TYPE,
		};
	},
});
</script>

<template>
	<div class="loading-container-bounce">
		<div class="loading-img-bounce">
			<img class="loading-fur-bounce" src="/assets/images/loading/icon-fur.webp" />
		</div>
		<div class="loading-bar-container-bounce">
			<div class="loading-bar-bounce">
				<div class="innerbar-bounce" :style="{ width: progress + '%' }"></div>
			</div>
		</div>
		<div class="title-container-bounce">
			<span class="loading-title-bounce">{{ title }}</span>
			<span class="loading-progress-bounce">{{ progress }}%</span>
		</div>
	</div>
</template>

<style scoped></style>
