import {getBrowserType, getMobileOS} from "@/utils/common-util";
import {allowBrowserList, BROWSER_TYPE, MOBILE_OS_TYPE, notAllowInappList} from "@/types";
import copy from "copy-to-clipboard";
import AppConfig from '@/constants';

/**
 * 커버 숨길 콘텐츠
 */
const notShowContentList = ['ca6a603b-33f6-48d0-bd75-7b7c0c2211b4', 'bNU0rbYdQK', 'e087e4e3-a8b1-4d87-b5a3-cafe5fcb131e', 'O6Jkmz1HY2'];

export const inappCoverEnable = (key: string) => {
   return notShowContentList.indexOf(key) < 0;
}

export const notAllowInapp = () => {
    const curBrowserType = getBrowserType();
    return !curBrowserType ||
    (curBrowserType &&
        (notAllowInappList.includes(curBrowserType) ||
            !allowBrowserList.includes(curBrowserType)));
}

export const openKakaoExternal = (msg: string) => {
    const ua = navigator.userAgent;
    const target_url = window.location.href;
    try {
        location.href =
            'kakaotalk://web/openExternal?url=' + encodeURIComponent(target_url);
        setTimeout(() => {
            location.href = /ipad|iphone|ipod/.test(ua)
                ? 'kakaoweb://closeBrowser'
                : 'kakaotalk://inappbrowser/close'
        })
    } catch(e) {
        console.error(e);
        copy(window.location.href);
        window.alert(msg);
    }
}

export const openAndroidExternal = (msg: string) => {
    const target_url = window.location.href;
    try {
        window.location.href = 'intent://'+ target_url.replace(/https?:\/\//i,'')+'#Intent;scheme=https;package=com.android.chrome;end';
    } catch (e) {
        console.error(e);
        copy(window.location.href);
        window.alert(msg);
    }
}

export const openExternal = (msg: string, move: boolean) => {
    copy(window.location.href);
    window.alert(msg);
    if (move) window.location.href = "x-web-search://?";
}