<script lang="ts">
import AppConfig from '@/constants';
import {BROWSER_TYPE, MOBILE_OS_TYPE, notAllowInappList} from "@/types";
import {getBrowserType, getMobileOS} from "@/utils/common-util";
import {openAndroidExternal, openExternal, openKakaoExternal} from '@/utils/inapp-view-util';
import {computed, defineComponent} from 'vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
	name: 'InappNoticePage',
	props: [],
	setup() {
		const { t, locale } = useI18n();
		const curBrowserType = getBrowserType();
		const curMobileOs = getMobileOS();
		const deviceMobile = !(!(curBrowserType && notAllowInappList.includes(curBrowserType)) &&
                !navigator.userAgent.toLocaleLowerCase().includes("mobile") &&
                window.innerWidth > 767);
		const showBottom = curBrowserType && notAllowInappList.includes(curBrowserType);
		const showButton = navigator.userAgent.toLocaleLowerCase().includes("mobile") || showBottom;
		const parentHost = (window.location != window.parent.location) ? document.referrer : document.location.href;
		const apocPlayHost = parentHost.startsWith(AppConfig.FRONT_HOST);
		const iosOtherBrowser = !(curMobileOs === MOBILE_OS_TYPE.IOS && curBrowserType !== BROWSER_TYPE.KAKAO); //아이폰 새 검색창 열기 여부

		const noticeBottomIconSrc = computed(() => {
			if (curBrowserType === BROWSER_TYPE.NAVER) {
           const isNaverBlog = navigator.userAgent
               .toLowerCase()
               .includes("inapp; blog");
           if (isNaverBlog) {
               if (curMobileOs === MOBILE_OS_TYPE.ANDROID) {
                   // 클릭 유도 아이콘 없음 + 지구본 아이콘과 '기본 브라우저' 멘트
                   return `/assets/images/in-app-notice/notice-bottom-naver-blog-android-${locale}`;
               } else if (curMobileOs === MOBILE_OS_TYPE.IOS) {
                   // 클릭 유도 아이콘 있음(확장 아이콘) + 아이콘 없이 '기본 브라우저' 멘트
                   return `/assets/images/in-app-notice/notice-bottom-naver-blog-iphone-${locale}`;
               }
           } else {
               if (curMobileOs !== MOBILE_OS_TYPE.OTHER) {
                   // 클릭 유도 아이콘 있음(햄버거 아이콘) + 지구본 아이콘과 '기본 브라우저' 멘트
                   return `/assets/images/in-app-notice/notice-bottom-naver-${locale}`;
               }
           }
           // 인스타
      } else if (curBrowserType === BROWSER_TYPE.INSTA || curBrowserType === BROWSER_TYPE.FACEBOOK) {
           if (curMobileOs === MOBILE_OS_TYPE.ANDROID) {
               // 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '크롬 혹은 삼성브라우저' 멘트
               return `/assets/images/in-app-notice/notice-bottom-insta-android-${locale}`;
           } else if (curMobileOs === MOBILE_OS_TYPE.IOS) {
               // 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '브라우저' 멘트
               return `/assets/images/in-app-notice/notice-bottom-insta-iphone-${locale}`;
           }
           // 카카오
      } else {
           if (curMobileOs === MOBILE_OS_TYPE.ANDROID) {
               // 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '다른 브라우저' 멘트
               return `/assets/images/in-app-notice/notice-bottom-android-${locale}`;
           } else if (curMobileOs === MOBILE_OS_TYPE.IOS) {
               // 클릭 유도 아이콘 있음(업로드 같은 아이콘) + 'Safari로 열기' 멘트
               return `/assets/images/in-app-notice/notice-bottom-ios2-${locale}`;
           }
      }
			return '';
		})

		/**
		 * 바로 가기 기능
		 */
		const directOpenExternalUrl = () => {
			if (apocPlayHost) {
                if (curMobileOs === MOBILE_OS_TYPE.ANDROID) {
					openAndroidExternal(t("msg.ALERT_PLEASE_URL_PASTE"));
                } else if (curMobileOs === MOBILE_OS_TYPE.IOS && curBrowserType === BROWSER_TYPE.KAKAO) {
					openKakaoExternal(t("msg.ALERT_PLEASE_URL_PASTE"));
                }
            }
		}

		setTimeout(() => {
			directOpenExternalUrl();
		}, 100);

		/**
		 * 타 브라우저 이동
		 */
		const handleBtnExtern = () => {
			if (curMobileOs === MOBILE_OS_TYPE.ANDROID) {
				openAndroidExternal(t("msg.ALERT_PLEASE_URL_PASTE"));
            } else if (curMobileOs === MOBILE_OS_TYPE.IOS) {
                    if (curBrowserType === BROWSER_TYPE.KAKAO) {
						openKakaoExternal(t("msg.ALERT_PLEASE_URL_PASTE"));
                    } else {
						openExternal(t("msg.ALERT_PLEASE_URL_PASTE_WHEN_BROWSER_OPEN"), true);
                    }
            } else {
				openExternal(t("msg.ALERT_PLEASE_URL_PASTE"), true);
            }
		}

		/**
		 * 복사 버튼
		 */
		const handleBtnCopyUrl = () => {
			openExternal(t("msg.ALERT_PLEASE_URL_PASTE"), false);
		}

		return {
			t,
			locale,
			curMobileOs,
			curBrowserType,
			deviceMobile,
			showBottom,
			showButton,
			BROWSER_TYPE,
			iosOtherBrowser,
			noticeBottomIconSrc,
			handleBtnExtern,
			handleBtnCopyUrl
		};
	},
});
</script>

<template>
	<div id="notice-inapp" :class="{'pc': !deviceMobile}">
		<div class="pc-notice-wrapper" v-if="!deviceMobile">
			<img :src="`/assets/images/in-app-notice/notice-other-browser-pc-${locale}.webp`"
				:srcset="`/assets/images/in-app-notice/notice-other-browser-pc-${locale}@2x.webp 2x, /assets/images/in-app-notice/notice-other-browser-pc-${locale}@3x.webp 3x`"
			/>
		</div>
		<!-- 중앙영역(로고, 설명, URL 버튼) -->
		<div class="wrap-center mobile" v-else>
			<!-- 아폭 로고 -->
			<img class="apoc-logo" name="noticeLogo" :src="`/assets/images/in-app-notice/logo_apoc_new.webp`"
				:srcset="`/assets/images/in-app-notice/logo_apoc_new@2x.webp 2x, /assets/images/in-app-notice/logo_apoc_new@3x.webp 3x`"
			/>
			<div></div>
			<!-- 로고 하단에 타 브라우저 유도 설명글 -->
			<img name="noticeText" :src="`/assets/images/in-app-notice/notice-text-${locale}.webp`"
				:srcset="`/assets/images/in-app-notice/notice-text-${locale}@2x.webp 2x, /assets/images/in-app-notice/notice-text-${locale}@3x.webp 3x`"
			/>
			<div name="btnDiv" v-if="showButton">
				<!-- '타 브라우저로 열기' 버튼 -->
				<img 
					v-if="iosOtherBrowser"
					name="btnExtern" :src="`/assets/images/in-app-notice/noteice-btn-extern-${locale}.webp`"
					:srcset="`/assets/images/in-app-notice/noteice-btn-extern-${locale}@2x.webp 2x, /assets/images/in-app-notice/noteice-btn-extern-${locale}@3x.webp 3x`"
					@click="handleBtnExtern"
				/>
				<!-- 'URL 복사하기' 버튼 -->
				<img name="btnCopyUrl" :src="`/assets/images/in-app-notice/notice-btn-copy-url-${locale}.webp`"
					:srcset="`/assets/images/in-app-notice/notice-btn-copy-url-${locale}@2x.webp 2x, /assets/images/in-app-notice/notice-btn-copy-url-${locale}@3x.webp 3x`"
					@click="handleBtnCopyUrl"
				/>
			</div>
		</div>
		<!-- 하단영역 (모바일 기기 자체의 타 브라우저 열기 아이콘 클릭 유도) -->
		<div class="wrap-bottom" :class="{'top': curBrowserType === BROWSER_TYPE.INSTA || curBrowserType === BROWSER_TYPE.FACEBOOK}" v-if="showBottom">
			<img name="noticeBottomIcon" :src="`${noticeBottomIconSrc}.webp`"
					:srcset="`${noticeBottomIconSrc}@2x.webp 2x, ${noticeBottomIconSrc}@3x.webp 3x`"
				/>
		</div>
	</div>
</template>
