<script lang="ts">
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ContentEntityDto } from '@/api/dto/content.dto';
import { contentMainImg, LOCALE, OPEN_TYPE_CODE, PARENT_CATEGORY_TYPE_CODE, STATE_YN } from '@/types';
import ApocLink from '@/components/common/ApocLink.vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import ContentsOptionSelect from '@/components/common/ContentsOptionSelect.vue';
import DynamicOptionSelect from '@/components/common/DynamicOptionSelect.vue';
import { HeaderSelectOption } from '@/components/common/apocSelect';
import { SearchCategoryListDto } from '@/api/dto/category.dto';
import { getCategoryList } from '@/api/category';
import { getApiClient } from '@/utils/apiClient';
import AppConfig from '@/constants';
import { compressZlib, loadLocalData, setEntityParameters } from '@/utils/common-util';
import { initStore } from '@/stores/store-manager';
import { POPUP_MODE } from '@/stores/state-store';
import { getContentDetail, saveContent } from '@/api/content';

export default defineComponent({
	name: 'ContentsPopup',
	components: { ContentsOptionSelect, ApocImageSet, ApocLink, DynamicOptionSelect },
	setup() {
		const { t } = useI18n();
		const apiClient = getApiClient(AppConfig.API_SERVER);
		const storeManager = initStore();
		const loginUser = ref();

		//각종 리스트
		const canvasThumbNailList = ref<HeaderSelectOption[]>([]);
		const categoryList = ref<HeaderSelectOption[]>([]);

		//공개상태
		const openTypeList = [
			{
				value: OPEN_TYPE_CODE.PRIVATE,
				label: t('common.openType.private'),
				// subLabel: t('common.descPrivate'),
				imageSrc: '/assets/images/common/icons/mypage/icon_private.svg',
			},
			{
				value: OPEN_TYPE_CODE.DIRECT,
				label: t('common.openType.direct'),
				// subLabel: t('common.descDirect'),
				imageSrc: '/assets/images/common/icons/mypage/icon_direct.svg',
			},
			{
				value: OPEN_TYPE_CODE.PUBLIC,
				label: t('common.openType.public'),
				// subLabel: t('common.descPublic'),
				imageSrc: '/assets/images/common/icons/mypage/icon_public.svg',
			},
		];

		//각종 값
		const lang = ref<string | null>(null); //설정 언어
		const savedContentIdx = computed(() => storeManager.dataStore.popupContentId);
		const saveData = ref<ContentEntityDto>(new ContentEntityDto());
		const showKidYnLabel = ref<boolean>(false); //유아 콘텐츠 라벨 클릭 유무
		const useKidYn = ref<boolean>(false); //유아 콘텐츠 사용 유무
		const openTypeLabel = ref<string>(''); //콘텐츠 공개상태
		const openTypeIcon = ref<string>(''); //콘텐츠 공개상태 아이콘

		// 셀렉트 박스 옵션 리스트 위치 계산할 부모 엘리먼트
		const selectOptionParentElement = ref<HTMLElement | undefined>(undefined);

		//썸네일 관련
		const contentMainImgFile = ref<File | null>(null); // 썸네일 메인 이미지
		const contentPrvImg = ref<contentMainImg | null>(null); // 썸네일 프리뷰 이미지
		const selectCanvasThumbNailIdx = ref<string>('');

		//닫기 버튼
		const closePopup = () => {
			storeManager.dataStore.setContentsTargetIdx(undefined);
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		// 썸네일 리스트 세팅
		const setThumbNailList = () => {
			const canvasManager = JSON.parse(saveData.value.contentBody);
			const canvasList = canvasManager.canvasList;
			for (let i = 0; i < canvasList.length; i++) {
				if (canvasList[i].thumbNail) {
					canvasThumbNailList.value.push({
						value: canvasList[i].thumbNail || '',
						label: t('common.page', [i + 1]),
						listLabel: t('common.page', [i + 1]),
						uuid: canvasList[i].uuid,
					});
				} else {
					canvasThumbNailList.value.push({
						value: '',
						label: t('popup.savePopup.setThumbnail'),
					});
				}
			}
		};

		// 썸네일 추가
		const addFile = (e: Event) => {
			const eventTarget = e.target as HTMLInputElement;
			if (eventTarget.files && eventTarget.files.length > 0) {
				contentMainImgFile.value = eventTarget?.files[0] as File;
				const prevUrl = URL.createObjectURL(eventTarget?.files[0] as Blob);
				if (prevUrl) {
					contentPrvImg.value = {
						name: eventTarget?.files[0].name,
						url: prevUrl,
					};
				}
				if (contentMainImgFile.value) saveData.value.contentMainImgFile = contentMainImgFile.value;
			}
			selectCanvasThumbNailIdx.value = '';
		};

		// 썸네일 삭제
		const removeFile = () => {
			contentMainImgFile.value = null;
			contentPrvImg.value = null;
			selectCanvasThumbNailIdx.value = '';
			saveData.value.contentMainImg = '';
		};

		// 유아 콘텐츠 라벨
		const toggleKidYnLabel = () => {
			showKidYnLabel.value = !showKidYnLabel.value;
		};

		// 유아 콘텐츠 여부
		const changeUseBabyState = () => {
			useKidYn.value = !useKidYn.value;
			saveData.value.kidYn = useKidYn.value ? STATE_YN.Y : STATE_YN.N;
		};

		// 썸네일 변경
		const changeThumbNail = (v: HeaderSelectOption) => {
			const selectedCanvasThumbnail = canvasThumbNailList.value.filter(option => option.value === v.value);
			if (selectedCanvasThumbnail.length > 0) {
				selectCanvasThumbNailIdx.value = selectedCanvasThumbnail[0].value;
				contentPrvImg.value = {
					name: selectedCanvasThumbnail[0].label,
					url: AppConfig.FILE_SERVER + selectedCanvasThumbnail[0].value,
				};
				saveData.value.canvasThumbNail = {
					fileOriginName: selectedCanvasThumbnail[0].label,
					mimeType: selectedCanvasThumbnail[0].value.split('.').pop(),
					saveFileName: selectedCanvasThumbnail[0].value,
				};
			} else {
				saveData.value.canvasThumbNail = null;
				contentPrvImg.value = null;
			}
		};

		// 카테고리 변경
		const selectCategory = (v: HeaderSelectOption) => {
			saveData.value.categoryIdx = v?.value;
		};

		// 공개상태 변경
		const selectOpenType = (v: HeaderSelectOption) => {
			saveData.value.openTypeCode = v?.value;
		};

		// 저장 데이터 생성
		const makeFormData = async (): Promise<FormData> => {
			const promiseFn = (fnResolve: (value: FormData) => void, fnReject: (reason?: any) => void) => {
				const saveContent = new ContentEntityDto();
				const formData = new FormData();
				setEntityParameters(saveContent, saveData.value);
				if (saveData.value.categoryIdx) formData.append('categoryIdx', saveData.value.categoryIdx);
				if (savedContentIdx.value) formData.append('contentIdx', savedContentIdx.value);
				if (saveData.value.contentName) formData.append('contentName', saveData.value.contentName);
				if (saveData.value.summaryDesc) formData.append('summaryDesc', saveData.value.summaryDesc);
				if (loginUser.value) formData.append('regrUserIdx', loginUser.value.userIdx);
				// formData.append('contentBodyFile', new Blob([compressZlib(JSON.stringify(getCanvasManager().getObjectData()))]), 'ctnt.apoc');
				formData.append('contentBodyFile', new Blob([compressZlib(saveData.value.contentBody)]), 'ctnt.apoc');
				formData.append('kidYn', saveData.value.kidYn);
				formData.append('openTypeCode', saveData.value.openTypeCode);
				formData.append('saveYn', STATE_YN.Y);

				//선택한 썸네일, 원래있던 이미지, 추가한 파일
				if (selectCanvasThumbNailIdx.value) formData.append('canvasThumbNail', JSON.stringify(saveData.value.canvasThumbNail));
				if (saveData.value.contentMainImg) formData.append('contentMainImg', saveData.value.contentMainImg);
				if (saveData.value.contentMainImgFile) formData.append('contentMainImgFile', saveData.value.contentMainImgFile);

				//썸네일이 없을시 첫번째 캔버스 썸네일로 대체
				// if (!selectCanvasThumbNailIdx.value && !saveData.value.contentMainImg && !saveData.value.contentMainImgFile) {
				// 	if (canvasThumbNailList.value && canvasThumbNailList.value.length > 0) {
				// 		saveData.value.canvasThumbNail = {
				// 			fileOriginName: canvasThumbNailList.value[0].label,
				// 			mimeType: canvasThumbNailList.value[0].value.split('.').pop(),
				// 			saveFileName: canvasThumbNailList.value[0].value,
				// 		};
				// 		formData.append('canvasThumbNail', JSON.stringify(saveData.value.canvasThumbNail));
				// 	}
				// }
				fnResolve(formData);
			};
			return new Promise(promiseFn);
		};

		// 저장
		const doSave = () => {
			makeFormData().then(res => {
				const formData = res;
				saveContent(apiClient, formData)
					.then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.TITLE_ERROR'));
						} else {
							if (res.data) {
								// if (res.data.contentMainImg) storeManager.dataStore.setContentThumbNail(res.data.contentMainImg);
								// if (res.data.contentName) StateManager.getInstance().contentName = res.data.contentName;
								window.alert(t('msg.RESULT_SAVE'));
								nextTick(() => {
									closePopup();
									window.location.reload();
								});

								// getCanvasManager().uuid = res.data.contentIdx;
								// getCanvasManager()
								// 	.saveData()
								// 	.then(() => {
								// 		closePopup();
								// 		window.dispatchEvent(new Event(ListEvent.LIST_UPDATE));
								// 	});
							}
						}
					})
					.catch(err => {
						if (typeof err === 'string') window.alert(t(err));
						else window.alert(t(err.message));
					});
			});
		};

		// 카테고리 리스트 조회
		const getCategoryTypeCode = () => {
			const param = new SearchCategoryListDto();
			param.parentCategoryTypeCode = PARENT_CATEGORY_TYPE_CODE.APC;
			getCategoryList(apiClient, param)
				.then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						if (res.data) {
							res.data.map(v => {
								//한국어인 경우
								if (lang.value === LOCALE.KO) {
									categoryList.value.push({
										value: v.categoryIdx,
										label: v.categoryName,
									});
									//영어인 경우
								} else {
									categoryList.value.push({
										value: v.categoryIdx,
										label: v.categoryEnName,
									});
								}
							});
						}
					}
				})
				.catch(e => {
					console.error(e);
				});
		};

		// 콘텐츠 정보 조회
		const bindContentDetail = () => {
			const param = new ContentEntityDto();
			param.contentIdx = savedContentIdx.value as string;
			getContentDetail(apiClient, param).then(res => {
				if (res.data) {
					const resultContent = res.data;

					// 기본 이름 locale
					// if (resultContent.contentName === DEFAULT_CONTENT_NAME.ENG || resultContent.contentName === DEFAULT_CONTENT_NAME.KOR) {
					// 	resultContent.contentName = t('defaultContentName');
					// }
					setEntityParameters(saveData.value, resultContent);

					// 저장 유무에 따른 썸네일 표기
					if (resultContent.saveYn === STATE_YN.Y) {
						if (resultContent.contentMainImg) {
							const dt = JSON.parse(resultContent.contentMainImg);
							contentPrvImg.value = {
								name: dt[0].fileName,
								url: AppConfig.FILE_SERVER + dt[0].saveFileName,
							};
						}
					} else {
						if (canvasThumbNailList.value.length > 0) {
							// 썸네일 선택 안했을시 1번 캔버스 썸네일 노출
							selectCanvasThumbNailIdx.value = canvasThumbNailList.value[0].value;
							contentPrvImg.value = {
								name: canvasThumbNailList.value[0].uuid || '',
								url: AppConfig.FILE_SERVER + selectCanvasThumbNailIdx.value,
							};

							saveData.value.canvasThumbNail = {
								fileOriginName: canvasThumbNailList.value[0].label,
								mimeType: canvasThumbNailList.value[0].value.split('.').pop(),
								saveFileName: canvasThumbNailList.value[0].value,
							};
						}
					}

					// 유아 콘텐츠 유무
					useKidYn.value = saveData.value.kidYn === STATE_YN.Y;

					// 공개상태 표기
					openTypeList.filter(item => {
						if (item.value === resultContent.openTypeCode) {
							openTypeLabel.value = item.label;
							openTypeIcon.value = item.imageSrc;
						}
					});
					// 선택에 따른 카테고리 리스트 노출
					// if (resultContent.categoryCode) {
					//   if (resultContent.categoryCode.indexOf(PARENT_CATEGORY_TYPE_CODE.BDL) > -1) {
					//     saveData.value.parentCategoryTypeCode = PARENT_CATEGORY_TYPE_CODE.BDL;
					//     categoryList.value = bdlCategoryList.value;
					//   } else {
					//     saveData.value.parentCategoryTypeCode = PARENT_CATEGORY_TYPE_CODE.APC;
					//     categoryList.value = apcCategoryList.value;
					//   }
					// } else {
					//   saveData.value.parentCategoryTypeCode = PARENT_CATEGORY_TYPE_CODE.APC;
					//   categoryList.value = apcCategoryList.value;
					// }

					//썸네일 리스트 세팅
					setThumbNailList();
				}
			});
		};
		onBeforeMount(() => {
			if (savedContentIdx.value) bindContentDetail();
		});

		onMounted(() => {
			lang.value = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG); //현재 언어
			loginUser.value = JSON.parse(loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER) as string);
			// saveData.value = storeManager.dataStore.contentsTargetInfo as ContentEntityDto;

			getCategoryTypeCode(); //카테고리 리스트 조회
			// bindContentDetail();

			//팝업 외 영역 스크롤 막기
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onUnmounted(() => {
			//풀기
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			lang,
			canvasThumbNailList,
			categoryList,
			openTypeList,
			openTypeLabel,
			openTypeIcon,
			saveData,
			showKidYnLabel,
			useKidYn,
			contentMainImgFile,
			contentPrvImg,
			selectOptionParentElement,
			closePopup,
			addFile,
			removeFile,
			toggleKidYnLabel,
			changeUseBabyState,
			changeThumbNail,
			selectCategory,
			selectOpenType,
			doSave,
		};
	},
	computed: {
		LOCALE() {
			return LOCALE;
		},
		OPEN_TYPE_CODE() {
			return OPEN_TYPE_CODE;
		},
	},
});
</script>
<template>
	<div class="popup-wrapper contents-popup">
		<div class="popups">
			<!-- 팝업 옵션 위치 -->
			<div id="content-popup-options"></div>
			<!-- 스크롤 영역 -->
			<div ref="selectOptionParentElement" class="save-popup-content-area">
				<div class="popup-title">
					<h2>{{ t('popup.savePopup.contentsInfo') }}</h2>
				</div>
				<!-- 본문 시작 -->
				<div class="save-popup-body">
					<div class="popup-content-area">
						<!-- 제목 -->
						<div id="title-section" class="popup-content-title">
							<span>{{ t('popup.savePopup.title') }}</span>
							<span class="text--primary">*</span>
						</div>
						<input
							id="title-input"
							v-model="saveData.contentName"
							name="title"
							class="popup-input-text"
							:placeholder="t('popup.savePopup.titleDes')"
							type="text"
							maxlength="200" />
						<!-- 콘텐츠 설명 -->
						<div id="desc-section" class="popup-content-title">
							<span>{{ t('popup.savePopup.desContents') }}</span>
						</div>
						<input
							id="desc-input"
							v-model="saveData.summaryDesc"
							class="popup-input-text"
							:placeholder="t('popup.savePopup.desContentsDes')"
							type="text" />
						<!-- 콘텐츠 썸네일 -->
						<div id="thumbnail-section" class="popup-content-title thumbnail">
							<span>{{ t('popup.savePopup.thumbnail') }}</span>
							<!--            <span v-if="saveData.openTypeCode === OPEN_TYPE_CODE.PUBLIC" class="text&#45;&#45;primary">*</span>-->
							<span class="text--primary">*</span>
						</div>
						<div class="popup-content-des">{{ t('popup.savePopup.thumbnailDes') }}</div>
						<div class="input-file-wrapper">
							<div v-if="!contentPrvImg" class="input-file-area">
								<!-- 이미지 업로드 -->
								<apoc-link v-if="contentMainImgFile" href="#" class="has-file" @click="removeFile">
									<span class="input-thumb-text">
										<i class="fa fa-regular fa-circle-x"></i>
										{{ contentMainImgFile.name }}
									</span>
								</apoc-link>
								<div v-else class="thumbnail-click-area">
									<label for="contentMainImgFile">
										<span class="input-thumb-text">
											{{ t('popup.savePopup.setImage') }}
										</span>
									</label>
									<input
										id="contentMainImgFile"
										class="input-thumb"
										name="contentMainImgFile"
										accept="image/*"
										type="file"
										:value="contentMainImgFile"
										@change="(e: Event) => addFile(e)" />
								</div>
								<!-- 캔버스에서 선택 -->
								<div class="popup-select-wrapper thumbnail">
									<contents-option-select
										:option-list="canvasThumbNailList"
										:placeholder="t('popup.savePopup.setThumbnail')"
										:select-option-paren="selectOptionParentElement as HTMLElement"
										:on-change="changeThumbNail"
										:is-check="false">
									</contents-option-select>
								</div>
							</div>
							<!-- 콘텐츠 파일명 or 선택한 캔버스명 -->
							<div v-if="contentPrvImg" class="input-file-area">
								<apoc-link href="#" class="w-10-imp" @click="removeFile">
									<label class="has-file w-10-imp">
										<span class="input-thumb-text">
											<i class="fa fa-regular fa-circle-x"></i>
											{{ contentPrvImg.name }}
										</span>
									</label>
								</apoc-link>
							</div>
							<!-- 미리보기 영역 -->
							<div v-if="contentPrvImg" class="popup-thumb-view popup-input-text">
								<apoc-image-set class="thumb-img" :src="contentPrvImg.url" :alt="contentPrvImg.name" />
							</div>
							<!-- 카테고리 -->
							<div id="category-section" class="popup-content-title category">
								<span>{{ t('popup.savePopup.category') }}</span
								><span v-if="saveData.openTypeCode === OPEN_TYPE_CODE.PUBLIC" class="text--primary">*</span>
								<span class="slash">/</span>
								<div class="toddler-check">
									<!--								<apoc-svg src="/assets/images/icon-basic/slash.svg" />-->
									<input id="baby-check" v-model="useKidYn" type="checkbox" @click="changeUseBabyState" />
									<label for="baby-check" :class="{ active: useKidYn }"> <i v-if="useKidYn" class="fa fa-solid fa-check baby-check-mark"></i> </label
									><span class="pointer" @click="changeUseBabyState">{{ t('popup.savePopup.forBaby') }}</span>
									<div class="info-icon" :class="{ active: showKidYnLabel }" @click="toggleKidYnLabel">i</div>
									<section v-if="showKidYnLabel">
										<div class="toddler-explain-arrow" />
										<div v-click-away="toggleKidYnLabel" class="toddler-explain">
											{{ t('popup.savePopup.forBabyDes') }}
										</div>
									</section>
								</div>
							</div>
							<!-- 카테고리 select -->
							<div class="popup-select-wrapper">
								<dynamic-option-select
									v-if="categoryList"
									:option-list="categoryList"
									:placeholder="
										saveData.categoryCode === null
											? t('popup.savePopup.categoryDes')
											: lang === LOCALE.KO
												? saveData.categoryName
												: saveData.categoryEnName
									"
									:select-option-parent="selectOptionParentElement as HTMLElement"
									:except-placeholder-from-list="false"
									:on-change="selectCategory">
								</dynamic-option-select>
							</div>
							<!-- 공개 여부 -->
							<div class="popup-content-title">
								<span>{{ t('common.openState') }}</span>
								<span class="text--primary">*</span>
							</div>
							<!-- 공개 여부 select -->
							<div class="popup-select-wrapper">
								<dynamic-option-select
									:option-list="openTypeList"
									:placeholder="openTypeLabel"
									:placeholder-img-src="openTypeIcon"
									:on-change="selectOpenType"
									:select-option-parent="selectOptionParentElement as HTMLElement">
								</dynamic-option-select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 스크롤 고정 영역(저장버튼) -->
			<!--			<div class="popup-save-btn-area-dimmed"></div>-->
			<div class="popup-save-btn-area">
				<!-- 타이틀, 닫기버튼 -->
				<apoc-link href="#" class="popup-btn close" @click="closePopup">
					{{ t('popup.savePopup.close') }}
				</apoc-link>
				<apoc-link href="#" class="popup-btn save" @click="doSave">
					{{ t('popup.savePopup.save') }}
				</apoc-link>
			</div>
		</div>
	</div>
</template>
