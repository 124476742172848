<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import AppConfig from '@/constants';
import { loadLocalData } from '@/utils/common-util';
import { useI18n } from 'vue-i18n';
import { LOCALE } from '@/types';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { Vue3Lottie } from 'vue3-lottie';
import { getCookie, setCookie } from 'typescript-cookie';
import { gymFood } from '@/assets/lottie';
import { RESOLUTION_TYPE } from '@/stores/data-store';

export default defineComponent({
	name: 'InspectionPopup',
	computed: {
		RESOLUTION_TYPE() {
			return RESOLUTION_TYPE;
		},
	},
	components: { Vue3Lottie, ApocImageSet },
	setup() {
		const storeManager = initStore();
		const { t } = useI18n();
		const disableContinue = ref<boolean>(true);
		const lang = ref<string | null>(null); //언어

		const onClickEmail = () => {
			window.location.href = 'mailto:cake@apoc.day';
		};
		const onClickClose24h = () => {
			setCookie(`popup_flag_inspection`, 'close', { expires: 1 });
			onClickCheck();
		};
		const onClickCheck = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		onMounted(() => {
			if (getCookie(`popup_flag_inspection`) === 'close') onClickCheck();
			else {
				lang.value = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
				lockBackgroundScroll();
			}
		});

		onBeforeUnmount(() => {
			unLockBackgroundScroll();
		});

		return {
			LOCALE,
			t,
			storeManager,
			disableContinue,
			lang,
			onClickEmail,
			onClickCheck,
			onClickClose24h,
			gymFood,
		};
	},
});
</script>

<template>
	<div class="inspection-popup">
		<div class="img-wrapper">
			<apoc-image-set
				v-if="storeManager.dataStore.page.resolution > RESOLUTION_TYPE.MOBILE"
				:src="lang === LOCALE.KO ? '/assets/images/popup/inspection_ko.png' : '/assets/images/popup/inspection_en.png'" />
			<apoc-image-set
				v-else
				:src="lang === LOCALE.KO ? '/assets/images/popup/inspection_ko_mobile.png' : '/assets/images/popup/inspection_en_mobile.png'" />
			<vue3-lottie class="lottie-apple" :animationData="gymFood" :height="200" :width="200" />
			<div class="inspection-btn-email" @click="onClickEmail" />
			<div class="inspection-btn-left" @click="onClickClose24h" />
			<div class="inspection-btn-right" @click="onClickCheck" />
		</div>
	</div>
</template>

<style scoped></style>
